import React from "react";

type MarkerTitleProps = {
  text: string;
};

export const MarkerTitle: React.FC<MarkerTitleProps> = (props) => {
  const { text } = props;

  return (
    <h2 className="text-center text-base md:text-xxxl font-bold antialiased mb-8 md:mb-[66px]">
      <span className="line-marker">{text}</span>
    </h2>
  );
};
