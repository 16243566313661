import * as React from "react";
import { BaseLayout } from "../../components/views/layouts/BaseLayout";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../hooks/useWindowSize";
import { UnderContentMv } from "../../components/templates/UnderContentMv";
import { BcItem } from "../../components/views/Breadcrumb";
import { MarkerTitle } from "../../components/parts/MarkerTitle";
import { ViewMoreButton } from "../../components/parts/ViewMoreButton";

const bcList: BcItem[] = [
  { href: "/service", text: "SERVICE" },
  { href: "", text: "FOR BUSINESS" },
];

const ServiceForBusinessPage = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <BaseLayout
      bcList={bcList}
      pageUrl="/service/for-business"
      pageTitle="事業者さま向けサービス | SERVICE"
      pageDescription="事業者さま向けにコーヒー豆の販売・関連器具の選定やアドバイス・販売を行なっております。事業者さまに合わせたオリジナルブレンドを配合し、ご提供させていただきます。"
    >
      <UnderContentMv jaTitle="事業者さま向けサービス" enTitle="FOR BUSINESS">
        {windowWidth < 480 ? (
          <StaticImage
            src="../../images/mv/forbusiness.jpg"
            alt="FOR BUSINESS メイン画像"
          />
        ) : (
          <div className="h-[420px] lg:h-[580px]">
            <StaticImage
              src="../../images/mv/forbusiness_pc.jpg"
              alt="FOR BUSINESS メイン画像"
              className="absolute inset-y-0 m-auto"
            />
          </div>
        )}
      </UnderContentMv>
      <section className="mb-[80px] md:mb-[120px]">
        <MarkerTitle text="コーヒー豆・コーヒー関連器具の選定 / 販売" />
        <p className="text-center mb-10 md:mb-[66px]">
          LOG COFFEE ROASTERSでは、事業者さま向けに
          <br className="md:hidden" />
          コーヒー豆の販売・関連器具の選定や
          <br className="md:hidden" />
          アドバイス・販売を行なっております。
          <br />
          事業者さまのイメージや、コーヒーをお出しする
          <br className="md:hidden" />
          お客さまについてなどをヒアリングさせていただき、
          <br />
          <span className="text-primary">
            事業者さまに合わせたオリジナルブレンド
          </span>
          を配合し、
          <br className="md:hidden" />
          ご提供させていただきます。
        </p>
        <div
          className="md:flex md:justify-center max-w-[1000px] px-5 mx-auto mb-10 md:mb-[76px]"
          data-page-animation={true}
          data-type-fade={true}
        >
          <div
            className="md:w-[46.9%] border border-card-border
            rounded-md py-7 md:py-[30px] px-5 md:px-[62px] mb-10 md:mb-0 md:mr-[6.2%]
          "
          >
            <h3 className="text-center font-bold md:text-lg antialiased mb-5 md:mb-7">
              <span className="text-[1.29em] text-primary">少量(300g)</span>
              からご注文いただけます
            </h3>
            <p className="mb-3 md:mb-7">
              小ロット(300g〜)のご注文が可能です。
              <span className="text-primary">(※)</span>
              <br />
              <span className="text-primary font-bold antialiased">
                「いろんなロースターのコーヒーを少しずつ提供したい」
              </span>
              といった事業者さまにもご好評をいただいております。
            </p>
            <div className="mb-4">
              <StaticImage
                src="../../images/for-business/image_01.jpg"
                alt=""
              />
            </div>
            <p className="text-xs text-primary text-center">
              (※) 事業者さまオリジナルブレンドでのご注文の場合は 1kg〜{" "}
            </p>
          </div>
          <div
            className="md:w-[46.9%] border border-card-border
            rounded-md py-7 md:py-[30px] px-5 md:px-[62px] mb-10 md:mb-0"
            data-page-animation={true}
            data-type-fade={true}
          >
            <h3 className="text-center font-bold md:text-lg antialiased mb-5 md:mb-7">
              ご要望を<span className="text-[1.29em] text-green">丁寧に</span>
              お伺いします
            </h3>
            <p className="mb-3 md:mb-7">
              どんなコーヒーにしたいか、といったご要望はもちろん、実際にコーヒーをお出しする方のことも
              <span className="text-green font-bold">丁寧にヒアリング</span>
              させていただき、最適なコーヒーをご提案いたします。
            </p>
            <div className="mb-4">
              <StaticImage
                src="../../images/for-business/image_02.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <p className="text-center text-primary mb-5">
          <span
            className="pseudo-before before:inline-block
            before:bg-line-left before:w-4 before:h-5 before:mr-2
            pseudo-after after:inline-block after:bg-line-right after:ml-2
            after:w-4 after:h-5"
            data-page-animation={true}
            data-type-fade={true}
          >
            お問い合わせはこちら
          </span>
        </p>
        <ViewMoreButton
          href="/contact?iqr=1"
          text="お問い合わせ"
          isPrimary={true}
        />
      </section>
      <section id="for-business-support">
        <MarkerTitle text="コーヒーに関するトータルサポート" />
        <p className="text-center font-bold antialiased">
          マシン・器具の選定から、オリジナルブレンドの配合、
          <br className="md:hidden" />
          バリスタトレーニングから販売サポートまで
          <br />
          <span className="text-primary">コーヒーに関するトータルサポート</span>
          を
          <br className="md:hidden" />
          行なっております。
        </p>
        <div className="max-w-[1240px] px-5 mx-auto mt-[40px]">
          <div
            className="relative max-w-[510px] mx-auto md:max-w-[100%]
            pt-[144px] md:pt-[216px] pc-small:pt-[170px] pb-[144px] md:pb-[34px]"
          >
            <div
              className="flex justify-center items-center absolute top-0 md:top-[78px]
              left-0
              bg-fukidashi-1 md:bg-fukidashi-1-pc bg-no-repeat bg-contain
              md:w-[240px] pc-small:w-[200px] tablet:!w-[182px] w-[152px]
              md:h-[156px] tablet:h-[120px] h-[108px] bg-center"
              data-page-animation={true}
              data-type-fade={true}
            >
              <p
                className="text-xs pc-small:text-sm md:text-base tablet:!text-xs
                text-white text-center leading-[1.4] relative md:static -top-1"
              >
                お店のコーヒーを
                <br />
                もっと特別感があって、
                <br />
                美味しいものにしたい！
              </p>
            </div>
            <div
              className="flex justify-center items-center absolute top-0
              left-auto right-0 md:right-auto
              md:left-[calc(50%_-_280px)]
              tablet-lg:left-[calc(50%_-_240px)]
              tablet:!left-[calc(50%_-_210px)]
              bg-fukidashi-2 md:bg-fukidashi-2-pc bg-no-repeat bg-contain
              md:w-[240px] pc-small:w-[200px] tablet:!w-[182px] w-[152px]
              md:h-[156px] tablet:h-[120px] h-[108px] bg-center"
              data-page-animation={true}
              data-type-fade={true}
            >
              <p
                className="text-xs pc-small:text-sm md:text-base tablet:!text-xs
                text-white text-center leading-[1.4] relative md:-top-1"
              >
                できるだけ多くの人に
                <br />
                楽しんでもらえるよう
                <br />
                コーヒーの種類を
                <br />
                増やしたい！
              </p>
            </div>
            <div
              className="flex justify-center items-center absolute md:top-0
              bottom-0 md:bottom-auto
              left-0
              md:left-auto
              md:right-[calc(50%_-_280px)]
              tablet-lg:right-[calc(50%_-_240px)]
              tablet:!right-[calc(50%_-_210px)]
              bg-fukidashi-3 md:bg-fukidashi-3-pc bg-no-repeat bg-contain
              md:w-[240px] pc-small:w-[200px] tablet:!w-[182px] w-[152px]
              md:h-[156px] tablet:h-[120px] h-[108px] bg-center"
              data-page-animation={true}
              data-type-fade={true}
            >
              <p
                className="text-xs pc-small:text-sm md:text-base tablet:!text-xs
                text-white text-center leading-[1.4] relative top-2 md:-top-1"
              >
                お店オリジナルの
                <br />
                コーヒー豆や
                <br className="md:hidden" />
                ドリップパックを
                <br />
                販売したい！
              </p>
            </div>
            <div
              className="flex justify-center items-center absolute
              md:top-[78px]
              bottom-0 md:bottom-auto
              right-0
              bg-fukidashi-4 md:bg-fukidashi-4-pc bg-no-repeat bg-contain
              md:w-[240px] pc-small:w-[200px] tablet:!w-[182px] w-[152px]
              md:h-[156px] tablet:h-[120px] h-[108px] bg-center"
              data-page-animation={true}
              data-type-fade={true}
            >
              <p
                className="text-xs pc-small:text-sm md:text-base tablet:!text-xs
                text-white text-center leading-[1.4]"
              >
                ノベルティとして
                <br />
                ドリップパックを提供したい！
              </p>
            </div>

            <h3 className="text-center font-bold md:text-lg antialiased mb-5 md:mb-7">
              <span className="text-[1.29em] text-green">どんなことでも</span>
              ご相談ください
            </h3>
            <p className="text-center font-bold antialiased">
              コーヒーと一括りに言っても、味は様々。
              <br />
              店舗コンセプトや店主様のお好みの味などを丁寧にヒアリングしながら、
              <br className="hidden md:block" />
              お店でご提供するオリジナルブレンドの作成や、機器選定、トレーニングをさせていただきます。
              <br />
              また、地域によって水質なども異なるため、最終調整は実際に現地に伺ってサポートさせていただきます。
              <br />
              <span className="text-primary">
                遠方からのご依頼も承っております
              </span>
              ので、 <br className="md:hidden" />
              お気軽にお問い合わせ下さい。
            </p>
          </div>
        </div>
        <div className="flex justify-center md:justify-between items-center max-w-[1240px] px-5 mx-auto">
          <div
            className="hidden md:block w-[240px] xl:w-[320px]"
            data-page-animation={true}
            data-type-fade={true}
          >
            <StaticImage src="../../images/for-business/image_03.jpg" alt="" />
          </div>
          <div className="mt-10 md:mt-0">
            <p className="text-center text-primary mb-5">
              <span
                className="pseudo-before before:inline-block
                before:bg-line-left before:w-4 before:h-5 before:mr-2
                pseudo-after after:inline-block after:bg-line-right after:ml-2
                after:w-4 after:h-5"
                data-page-animation={true}
                data-type-fade={true}
              >
                お問い合わせはこちら
              </span>
            </p>
            <ViewMoreButton
              href="/contact?iqr=2"
              text="お問い合わせ"
              isPrimary={true}
            />
          </div>
          <div
            className="hidden md:block w-[240px] xl:w-[320px]"
            data-page-animation={true}
            data-type-fade={true}
          >
            <StaticImage src="../../images/for-business/image_04.jpg" alt="" />
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default ServiceForBusinessPage;
